import React from 'react'
import classes from "./Services.module.scss"
import Container from '../../layout'
import Title from '../Title'
import icon from "../../images/services/services_icon.png"
import icon2 from "../../images/services/services_icon_2.png"
import icon3 from "../../images/services/services_icon_3.png"


const Services = () => {
    return (
        <>
            <Container>
                <Title className="services__title">BIZNING XIZMATLARIMIZ</Title>
                <div className={classes["subtitle"]}>
                    "Aliprint"  kompaniyasi tashqi reklama va poligrafiya mahsulotlari ishlab chiqarish bozorining yetakchisi.
                    Biz yuqori sifatli reklama konstruktsiyalari va poligrafiya mahsulotlarini ishlab chiqish va ishlab chiqarishni taklif qilamiz.
                </div>

                <div className={classes["services"]}>
                    <div className={classes["services__item"]}>
                        <div className={classes["services__item-icon"]}>
                            <img src={icon} alt="" />
                        </div>
                        <h3 className={classes["services__item-title"]}>Tashqi reklama</h3>
                        <p className={classes["services__item-text"]}>
                            Billbordlar, yozuvlar, yoritilgan quti va neon reklamalarni ishlab chiqish va o'rnatish.
                            Bannerlar, shtenderlar va reklama shitlarini ishlab chiqarish va o'rnatish.
                            Vitrinalar, avtotransport va boshqa yuzalarni brending qilish.</p>
                    </div>

                    <div className={classes["services__item"]}>
                        <div className={classes["services__item-icon"]}>
                            <img src={icon2} alt="" />
                        </div>
                        <h3 className={classes["services__item-title"]}>Poligrafiya mahsulotlari</h3>
                        <p className={classes["services__item-text"]}>
                            Vizitka, buklet, listovka va plakatlarni ishlab chiqarish.
                            Kataloglar, broshyuralar, jurnallar va kalendarlarni bosib chiqarish.
                            Firma blanklari, papkalar va boshqa turadgi poligrafiya maxsulatlari bosib chiqarish.</p>
                    </div>

                    <div className={classes["services__item"]}>
                        <div className={classes["services__item-icon"]}>
                            <img  src={icon3} alt="" />
                        </div>
                        <h3 className={classes["services__item-title"]}>Dizayn Xizmati</h3>
                        <p className={classes["services__item-text"]}>
                            Billbordlar, yozuvlar, yoritilgan quti va neon reklamalarni ishlab chiqish va o'rnatish.
                            Bannerlar, shtenderlar va reklama shitlarini ishlab chiqarish va o'rnatish.
                            Vitrinalar, avtotransport va boshqa yuzalarni brending qilish.</p>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Services