import React from 'react'
import classes from "./Advantages.module.scss"
import Container from '../../layout'
import Title from '../Title'
import icon from "../../images/advantages/icon.png"
import icon2 from "../../images/advantages/icon2.png"
import icon3 from "../../images/advantages/icon3.png"
import icon4 from "../../images/advantages/icon4.png"


const Advantages = () => {
  return (
    <>
    <Container>
        <Title className="advantages__title">Biz bilan ishlashning afzalliklari</Title>
        <div className={classes["advantages"]}>
                <div className={classes["advantages__item"]}>
                    <img src={icon} alt="" />
                    <h3 className={classes["advantages__item-title"]}>Yuqori sifat</h3>
                    <p className={classes["advantages__item-text"]}>Biz zamonaviy texnologiyalar va materiallardan foydalanamiz, bu mahsulotimiz uzoq muddatli va estetik jozibadorligini kafolatlaydi.
                    </p>
                </div>

                <div className={classes["advantages__item"]}>
                    <img src={icon2} alt="" />
                    <h3 className={classes["advantages__item-title"]}>Shaxsiy yondashuv</h3>
                    <p className={classes["advantages__item-text"]}>Har bir loyihani mijozning o'ziga xos ehtiyojlari va xohishlarini hisobga olgan holda ishlab chiqamiz.
                    </p>
                </div>

                <div className={classes["advantages__item"]}>
                    <img src={icon3} alt="" />
                    <h3 className={classes["advantages__item-title"]}>Operativlik</h3>
                    <p className={classes["advantages__item-text"]}>Biz vaqtingizni qadrlaymiz va buyurtmalarni qisqa muddatlarda bajarishga kafolat beramiz.

                    </p>
                </div>

                <div className={classes["advantages__item"]}>
                    <img src={icon4} alt="" />
                    <h3 className={classes["advantages__item-title"]}>Raqobatbardosh narxlar</h3>
                    <p className={classes["advantages__item-text"]}>Bizning narx siyosatimiz keng mijozlar doirasi uchun xizmatlarimizga kirish imkoniyatini ta'minlashga yo'naltirilgan

                    </p>
                </div>
        </div>
    </Container>
    </>
  )
}

export default Advantages