import React,  { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination,Autoplay } from 'swiper/modules';

import classes from './Header.module.scss';
import image from '../../images/ofis/image.jpg';
import image_2 from '../../images/ofis/image_2.jpg';
import image_3 from '../../images/ofis/image_3.jpg';
import image_4 from '../../images/ofis/image_4.jpg';
import image_5 from '../../images/ofis/image_5.jpg';
import image_6 from '../../images/ofis/image_6.jpg';
import image_7 from '../../images/ofis/image_7.jpg';
import image_8 from '../../images/ofis/image_8.jpg';


const Header = () => {
  return (
    <div className={classes['header']}>
      <Swiper autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }} 
        pagination={true}
        modules={[Pagination,Autoplay]}
        className="mySwiper">
        <SwiperSlide><img src={image} alt="" /></SwiperSlide>
        <SwiperSlide><img src={image_2} alt="" /></SwiperSlide>
        <SwiperSlide><img src={image_3} alt="" /></SwiperSlide>
        <SwiperSlide><img src={image_4} alt="" /></SwiperSlide>
        <SwiperSlide><img src={image_5} alt="" /></SwiperSlide>
        <SwiperSlide><img src={image_6} alt="" /></SwiperSlide>
        <SwiperSlide><img src={image_7} alt="" /></SwiperSlide>
        <SwiperSlide><img src={image_8} alt="" /></SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Header;
